import React, { Component, Suspense } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Portfolio from './Components/Portfolio';
import i18n from "./i18n";
import {initReactI18next} from 'react-i18next';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      resumeData: {}
    };

    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);

  }

  switchLang(lang) {
    this.getResumeData(lang);
  } 

  getResumeData(lang = 'en'){
    //The language is set here to make sure that on the first display the lenguage of the json data and the website match
    i18n.use(initReactI18next) 
    .init({lng: lang});

    var urlJson =  '/resumeData.json';
    if( lang === 'es'){
      urlJson =  '/resumeDataEs.json';     
    }

    $.ajax({
      url: urlJson,
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({resumeData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getResumeData();
  }

  render() {
    return (
      <div className="App">
        <Suspense fallback={(<div></div>)}>
          <Header data={this.state.resumeData.main} switchLang={this.switchLang.bind(this)}/>
          <About data={this.state.resumeData.main}/>
          <Resume data={this.state.resumeData.resume}/>
          <Portfolio data={this.state.resumeData.portfolio}/>
          <Footer data={this.state.resumeData.main}/>
        </Suspense>
      </div>
    );
  }
}

export default App;
