import React, { Component } from 'react';
import i18n from "i18next";
import {withTranslation, initReactI18next} from 'react-i18next';

class Header extends Component {
   childswitchLang=(lng)=>{
      this.props.switchLang(lng);
   }
   
  render() {
    const { t } = this.props;
    
    if(this.props.data){
      var name = this.props.data.name;
      var description= this.props.data.description;
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }
    
    
    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">{t("Home")}</a></li>
            <li><a className="smoothscroll" href="#about">{t("About")}</a></li>
	         <li><a className="smoothscroll" href="#resume">{t("Resume")}</a></li>
            <li><a className="smoothscroll" href="#portfolio">{t("Works")}</a></li>
            <button className='lng' onClick={() => {this.childswitchLang("en")}}><img src='/images/en.png' width='25px' alt='{t("English")}'></img></button>
            <button className='lng' onClick={() => {this.childswitchLang("es")}}><img src='/images/es.png' width='25px' alt='{t("Spanish")}'></img></button>
         </ul>
          

      </nav>

      <div className="row banner">
         <div className="banner-text">
            <h1 className="responsive-headline">{t("I'm")} {name}</h1>
            <h3>{description}.</h3>
            <hr />
            <ul className="social">
               {networks}
            </ul>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
      </p>
F
   </header>
    );
  }
}

export default withTranslation()(Header);
